import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

import { Box, Paper, Typography } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditBenefits({ open, hotelData, handleClose }) {
  const [state, setstate] = useState("");
  const image = hotelData.image;
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    link:"",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        title: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        link:formData.link,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.title).forEach((key) => {
        formDataToSend.append(`title[${key}]`, data.title[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });
      formDataToSend.append("link", data.link);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/benefits/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Benefit updated:", response.data.data);
        handleClose();
        setError("Benefit updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Benefit");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Benefit:", error);
      setError("Error updating Benefit");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hotelData) {
      // Set formData based on hotelData when it's available
      setFormData({
        en: hotelData && hotelData.title && hotelData.title.en,
        ar: hotelData && hotelData.title && hotelData.title.ar,
        fr: hotelData && hotelData.title && hotelData.title.fr,
        ur: hotelData && hotelData.title && hotelData.title.ur,
        tr: hotelData && hotelData.title && hotelData.title.tr,
        sw: hotelData && hotelData.title && hotelData.title.sw,
        den: hotelData && hotelData.description && hotelData.description.en,
        dar: hotelData && hotelData.description && hotelData.description.ar,
        dfr: hotelData && hotelData.description && hotelData.description.fr,
        dur: hotelData && hotelData.description && hotelData.description.ur,
        dtr: hotelData && hotelData.description && hotelData.description.tr,
        dsw: hotelData && hotelData.description && hotelData.description.sw,
        link: hotelData && hotelData.link
      });
    }
  }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit Benefit Detail of{" "}
          {hotelData && hotelData.title && hotelData.title.en}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Benefit details or delete and add them again.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="shop-name"
            label="Benefit Title in English"
            type="text"
            fullWidth
            name="en"
            defaultValue={hotelData && hotelData.title && hotelData.title.en}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Benefit Title in Arabic"
            type="text"
            fullWidth
            name="ar"
            defaultValue={hotelData && hotelData.title && hotelData.title.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Benefit Title in French"
            type="text"
            fullWidth
            name="fr"
            defaultValue={hotelData && hotelData.title && hotelData.title.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Benefit Title in Urdu"
            type="text"
            fullWidth
            name="ur"
            defaultValue={hotelData && hotelData.title && hotelData.title.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Benefit Title in Turkish"
            type="text"
            fullWidth
            name="tr"
            defaultValue={hotelData && hotelData.title && hotelData.title.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Benefit Title in Swahili"
            type="text"
            fullWidth
            name="sw"
            defaultValue={hotelData && hotelData.title && hotelData.title.sw}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.en
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ar
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.fr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ur
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.tr
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Benefit Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.sw
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Link"
            type="url"
            fullWidth
            name="link"
            defaultValue={hotelData && hotelData.link}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                value={formData.image}
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Benefit Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditBenefits;
