import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { withStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
  addicon: {
    fontSize: 18, // adjust the size as needed
  },
  removeicon: {
    fontSize: 18, // adjust the size as needed
  },
});

function NewEvent({ openadmin, handleClose, classes, ref }) {
  // for image preview
  const [state, setstate] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    lat: "",
    lng: "",
    address: "",
    event_time: "",
    event_date: "",
    phone_one: "",
    phone_two: "",
    phone_three: ""
  });

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0'); // Months are zero-based
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [eventDateTime, setEventDateTime] = useState(getCurrentDateTime());
  const handleDateTimeChange = (event) => {
    setEventDateTime(event.target.value);
  };
  

  const [phoneErrors, setPhoneErrors] = useState({
    phone_one: false,
    phone_two: false,
    phone_three: false,
  });

  const validatePhoneNumber = (name, value) => {
    if (value.length < 9 || value.length > 20) {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "phone_one" || name === "phone_two" || name === "phone_three") {
      validatePhoneNumber(name, value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const [eventDate, eventTime] = eventDateTime.split('T');
      const formattedTime = eventTime + ":00"; // Adding seconds

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        lat: formData.lat,
        lng: formData.lng,
        address: formData.address,
        event_time: formattedTime,
        event_date: eventDate,
        phone_one: formData.phone_one,
        phone_two: formData.phone_two,
        phone_three: formData.phone_two
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      formDataToSend.append("address", data.address);
      formDataToSend.append("event_time", data.event_time);
      formDataToSend.append("event_date", data.event_date);
      formDataToSend.append("phone_one", data.phone_one);
      formDataToSend.append("phone_two", data.phone_two);
      formDataToSend.append("phone_three", data.phone_three);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);

        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/events", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        for (let [key, value] of formDataToSend.entries()) {
          console.log(`${key}: ${value}`);
        }

        if (response.data.success) {
          console.log("New event added:", response.data.data);
         
          setFormData({
            en: "",
            ar: "",
            fr: "",
            ur: "",
            tr: "",
            sw: "",
            den: "",
            dar: "",
            dfr: "",
            dur: "",
            dtr: "",
            dsw: "",
            lat: "",
            lng: "",
            address: "",
            event_time: "",
            event_date: "",
            phone_one: "",
            phone_two: "",
            phone_three: ""
          });
          setstate("");
          setSelectedFile(null);
          handleClose(); // Close dialog after successful addition
          setError("New Event added");
          setSnackbarOpen(true);
        }
      } else {
        setError("Kindly select the image file");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding Event:", error);
      setError("Error adding Event");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Event Detail</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new Event just enter the following details all are required
            fields.
          </DialogContentText>

          <TextField
            id="datetime-local"
            label="Event Date,  Time"
            type="datetime-local"
            defaultValue={eventDateTime}
              value={eventDateTime}
              onChange={handleDateTimeChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
           <TextField
             autoFocus
            margin="dense"
            id="shop-name"
            label="Event Name in English"
            type="text"
            fullWidth
            name="en"
            error={formData.en === ""}
            value={formData.en}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Arabic"
            type="text"
            fullWidth
            name="ar"
            error={formData.ar === ""}
            value={formData.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in French"
            type="text"
            fullWidth
            name="fr"
            error={formData.fr === ""}
            value={formData.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Urdu"
            type="text"
            fullWidth
            name="ur"
            error={formData.ur === ""}
            value={formData.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Turkish"
            type="text"
            fullWidth
            error={formData.tr === ""}
            name="tr"
            value={formData.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Swahili"
            type="text"
            fullWidth
            name="sw"
            error={formData.sw === ""}
            value={formData.sw}
            onChange={handleChange}
          />

<TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            error={formData.den === ""}
            value={formData.den}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            error={formData.dar === ""}
            value={formData.dar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            error={formData.dfr === ""}
            value={formData.dfr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            error={formData.dur === ""}
            value={formData.dur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            error={formData.dtr === ""}
            value={formData.dtr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            error={formData.dsw === ""}
            value={formData.dsw}
            onChange={handleChange}
          />


          <TextField
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            name="address"
            error={formData.address === ""}
            value={formData.address}
            onChange={handleChange}
          />
         

<Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Event Main Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          <TextField
             
            margin="dense"
            id="latitude"
            label="Latitude"
            type="number"
            fullWidth
            name="lat"
            error={formData.lat === ""}
            value={formData.lat}
            onChange={handleChange}
          />
          <TextField
             
            margin="dense"
            id="longitude"
            label="Longitude"
            type="number"
            fullWidth
            name="lng"
            error={formData.lng === ""}
            value={formData.lng}
            onChange={handleChange}
          />
         
         <TextField
            margin="dense"
            id="phonenumber1"
            label="Phone Number 1"
            type="number"
            fullWidth
            name="phone_one"
            error={phoneErrors.phone_one}
            helperText={phoneErrors.phone_one ? "Phone number must be between 9 and 20 digits" : ""}
            value={formData.phone_one}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber2"
            label="Phone Number 2"
            type="number"
            fullWidth
            name="phone_two"
            error={phoneErrors.phone_two}
            helperText={phoneErrors.phone_two ? "Phone number must be between 9 and 20 digits" : ""}
            value={formData.phone_two}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber3"
            label="Phone Number 3"
            type="number"
            fullWidth
            name="phone_three"
            error={phoneErrors.phone_three}
            helperText={phoneErrors.phone_three ? "Phone number must be between 9 and 20 digits" : ""}
            value={formData.phone_three}
            onChange={handleChange}
          />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default withStyles(styles)(NewEvent);
