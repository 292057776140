import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { withStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
  addicon: {
    fontSize: 16, // adjust the size as needed
  },
  removeicon: {
    fontSize: 16, // adjust the size as needed
  },
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function NewHotel({ openadmin, handleClose, classes, ref }) {
  // for image preview
  const [state, setstate] = useState("");
  const [state2, setstate2] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const image2 = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    price: "",
    stars_count: "",
    users_ratings_count: "",
    rate_stars_count: "",
    lat: "",
    lng: "",
    booking_url: "",
    phone_one: "",
    phone_two: "",
    phone_three: "",
    sort: "",
    booking_rate: "",
    tripadvisor_rate: "",
    agoda_rate: "",
  });

  const [phoneErrors, setPhoneErrors] = useState({
    phone_one: false,
    phone_two: false,
    phone_three: false,
  });

  const validatePhoneNumber = (name, value) => {
    if (value.length < 9 || value.length > 20) {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "phone_one" ||
      name === "phone_two" ||
      name === "phone_three"
    ) {
      validatePhoneNumber(name, value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  var loadFile2 = (event) => {
    if (event.target.files) {
      const file2 = event.target.files[0];
      setstate2(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile2(file2);
      console.log(fileURL);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        price: formData.price,
        lat: formData.lat,
        lng: formData.lng,
        booking_url: formData.booking_url,
        stars_count: formData.stars_count,
        users_ratings_count: formData.users_ratings_count,
        rate_stars_count: formData.rate_stars_count,
        phone_one: formData.phone_one,
        phone_two: formData.phone_two,
        phone_three: formData.phone_three,
        sort: formData.sort,
        booking_rate: formData.booking_rate,
        tripadvisor_rate: formData.tripadvisor_rate,
        agoda_rate: formData.agoda_rate,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("price", data.price);
      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      formDataToSend.append("booking_url", data.booking_url);
      formDataToSend.append("stars_count", data.stars_count);
      formDataToSend.append("users_ratings_count", data.users_ratings_count);
      formDataToSend.append("rate_stars_count", data.rate_stars_count);
      formDataToSend.append("phone_one", data.phone_one);
      formDataToSend.append("phone_two", data.phone_two);
      formDataToSend.append("phone_three", data.phone_three);
      formDataToSend.append("sort", data.sort);
      formDataToSend.append("booking_rate", data.booking_rate);
      formDataToSend.append("tripadvisor_rate", data.tripadvisor_rate);
      formDataToSend.append("agoda_rate", data.agoda_rate);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
        formDataToSend.append("logo", selectedFile2);

        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/hotels", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          console.log("New hotel added:", response.data.data);

          setFormData({
            en: "",
            ar: "",
            fr: "",
            ur: "",
            tr: "",
            sw: "",
            den: "",
            dar: "",
            dfr: "",
            dur: "",
            dtr: "",
            dsw: "",
            price: "",
            rate_stars_count: "",
            lat: "",
            lng: "",
            users_ratings_count: "",
            stars_count: "",
            booking_url: "",
            phone_one: "",
            phone_two: "",
            phone_three: "",
            sort: "",
            booking_rate: "",
            tripadvisor_rate: "",
            agoda_rate: "",
          });
          setstate("");
          setstate2("");
          setSelectedFile(null);
          setSelectedFile2(null);
          handleClose(); // Close dialog after successful addition
          setError("New Hotel added");
          setSnackbarOpen(true);
        }
      } else {
        setError("Kindly select the image file");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding hotels:", error);
      setError("Error adding hotels");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Hotel Detail</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new hotel just enter the following details all are required
            fields
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Hotel Name (English)"
            type="text"
            fullWidth
            name="en"
            error={formData.en === ""}
            value={formData.en}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            error={formData.ar === ""}
            value={formData.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (French)"
            type="text"
            fullWidth
            name="fr"
            error={formData.fr === ""}
            value={formData.fr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            error={formData.ur === ""}
            value={formData.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Turkish)"
            type="text"
            fullWidth
            error={formData.tr === ""}
            name="tr"
            value={formData.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Swahili)"
            type="text"
            fullWidth
            name="sw"
            error={formData.sw === ""}
            value={formData.sw}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            error={formData.den === ""}
            value={formData.den}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            error={formData.dar === ""}
            value={formData.dar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            error={formData.dfr === ""}
            value={formData.dfr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            error={formData.dur === ""}
            value={formData.dur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            error={formData.dtr === ""}
            value={formData.dtr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Hotel Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            error={formData.dsw === ""}
            value={formData.dsw}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            name="price"
            error={formData.price === ""}
            value={formData.price}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="ratiing"
            label="Rating"
            type="text"
            name="rate_stars_count"
            fullWidth
            error={formData.rate_stars_count === ""}
            value={formData.rate_stars_count}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Users Ratings Count"
            type="text"
            name="users_ratings_count"
            fullWidth
            error={formData.users_ratings_count === ""}
            value={formData.users_ratings_count}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Stars Count"
            type="text"
            name="stars_count"
            fullWidth
            error={formData.stars_count === ""}
            value={formData.stars_count}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Order"
            type="number"
            name="sort"
            fullWidth
            error={formData.sort === ""}
            value={formData.sort}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Booking Rate"
            type="number"
            name="booking_rate"
            fullWidth
            error={formData.booking_rate === ""}
            value={formData.booking_rate}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="ratiing"
            label="Tripadvisor Rate"
            type="number"
            name="tripadvisor_rate"
            fullWidth
            error={formData.tripadvisor_rate === ""}
            value={formData.tripadvisor_rate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Agoda Rate"
            type="number"
            name="agoda_rate"
            fullWidth
            error={formData.agoda_rate === ""}
            value={formData.agoda_rate}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                multiple
                value={formData.image}
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Hotel Main Image *
              </Typography>

              <img
                src={state ? state : image}
                className={classes.image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          <Paper
            style={{
              width: "150px",
              margin: "10px 0px",
              background: "#364B5F",
            }}
          >
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image2"
                multiple
                id="file2"
                value={formData.image2}
                onChange={loadFile2}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                  color: "#9DA07C",
                }}
              >
                Logo (png) *
              </Typography>

              <img
                src={state2 ? state2 : image2}
                className={classes.image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file2" style={{ cursor: "pointer" }}>
                <PhotoCamera style={{ color: "#9DA07C" }} />
              </label>
            </Box>
          </Paper>

          <TextField
            margin="dense"
            id="phonenumber1"
            label="Phone Number 1"
            type="number"
            fullWidth
            name="phone_one"
            error={phoneErrors.phone_one}
            helperText={
              phoneErrors.phone_one
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_one}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber2"
            label="Phone Number 2"
            type="number"
            fullWidth
            name="phone_two"
            error={phoneErrors.phone_two}
            helperText={
              phoneErrors.phone_two
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_two}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber3"
            label="Phone Number 3"
            type="number"
            fullWidth
            name="phone_three"
            error={phoneErrors.phone_three}
            helperText={
              phoneErrors.phone_three
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_three}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="latitude"
            label="latitude"
            type="number"
            name="lat"
            fullWidth
            error={formData.lat === ""}
            value={formData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="longitude"
            label="longitude"
            type="number"
            name="lng"
            fullWidth
            error={formData.lng === ""}
            value={formData.lng}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="booknowlink"
            label="Book Now Link"
            type="text"
            fullWidth
            name="booking_url"
            error={formData.booking_url === ""}
            value={formData.booking_url}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default withStyles(styles)(NewHotel);
