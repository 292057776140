import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditInvestment({ open, handleClose, hotelData }) {
  // for image preview
  const [state, setstate] = useState("");
  // const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const image = hotelData.image;
  const pdf = hotelData.pdf_file;
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [staticErrorOpen, setStaticErrorOpen] = useState(false);
  const [error, setError] = useState(null);
  const [staticError, setStaticError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState(null);

  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    status: "",
    city: "",
    type: "",
    building_area: "",
    land_area: "",
    units_count: "",
    floors: "",
    lat: "",
    lng: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
    const handleStaticErrorClose = () => {
      setStaticErrorOpen(false);
    };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const loadPDFFile = (event) => {
    if (event.target.files) {
      const pdfFile = event.target.files[0];
      setSelectedPDF(pdfFile); // Store the selected PDF file
    }
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        status: formData.status,
        city: formData.city,
        type: formData.type,
        building_area: formData.building_area,
        land_area: formData.land_area,
        units_count: formData.units_count,
        floors: formData.floors,
        lat: formData.lat,
        lng: formData.lng,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("status", data.status);
      formDataToSend.append("city", data.city);
      formDataToSend.append("type", data.type);
      formDataToSend.append("building_area", data.building_area);
      formDataToSend.append("land_area", data.land_area);
      formDataToSend.append("units_count", data.units_count);
      formDataToSend.append("floors", data.floors);
      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      if (selectedPDF) {
        formDataToSend.append("pdf_file", selectedPDF); // Append the PDF file to the FormData
      }

      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/projects/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Investment Opportunities updated:", response.data.data);
        handleClose();
        setError("Investment Opportunities updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Investment Opportunities");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Investment Opportunities:", error);
      setError("Error updating Investment Opportunities");

      if (error.response && error.response.data) {
        // Extract and format error messages from the API response
        const { message, data } = error.response.data;
        const formattedErrors = [message];

        if (data) {
          Object.keys(data).forEach((key) => {
            formattedErrors.push(...data[key]);
          });
        }

        setStaticError(formattedErrors.join(" | "));
      }
      setStaticErrorOpen(true);
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // This effect runs when hotelData changes
    if (hotelData) {
      // Set formData based on hotelData when it's available
      setFormData({
        en: hotelData && hotelData.name && hotelData.name.en,
        ar: hotelData && hotelData.name && hotelData.name.ar,
        fr: hotelData && hotelData.name && hotelData.name.fr,
        ur: hotelData && hotelData.name && hotelData.name.ur,
        tr: hotelData && hotelData.name && hotelData.name.tr,
        sw: hotelData && hotelData.name && hotelData.name.sw,
        den: hotelData && hotelData.description && hotelData.description.en,
        dar: hotelData && hotelData.description && hotelData.description.ar,
        dfr: hotelData && hotelData.description && hotelData.description.fr,
        dur: hotelData && hotelData.description && hotelData.description.ur,
        dtr: hotelData && hotelData.description && hotelData.description.tr,
        dsw: hotelData && hotelData.description && hotelData.description.sw,
        status: hotelData.status,
        city: hotelData.city,
        type: hotelData.type,
        building_area: hotelData.building_area,
        land_area: hotelData.land_area,
        units_count: hotelData.units_count,
        floors: hotelData.floors,
        lat: hotelData.lat,
        lng: hotelData.lng,
      });
    }
  }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Editing Investment Detail of{" "}
          {hotelData && hotelData.name && hotelData.name.en}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Investment details or delete and add them
            again.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Investment Name (English)"
            type="text"
            fullWidth
            name="en"
            defaultValue={hotelData && hotelData.name && hotelData.name.en}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            defaultValue={hotelData && hotelData.name && hotelData.name.ar}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (French)"
            type="text"
            fullWidth
            name="fr"
            defaultValue={hotelData && hotelData.name && hotelData.name.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            defaultValue={hotelData && hotelData.name && hotelData.name.ur}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Turkish)"
            type="text"
            fullWidth
            name="tr"
            defaultValue={hotelData && hotelData.name && hotelData.name.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Swahili)"
            type="text"
            fullWidth
            name="sw"
            defaultValue={hotelData && hotelData.name && hotelData.name.sw}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.en
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ar
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.fr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ur
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.tr
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.sw
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="price"
            label="Status"
            type="text"
            fullWidth
            name="status"
            defaultValue={hotelData.status}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="ratiing"
            label="City"
            type="text"
            name="city"
            fullWidth
            defaultValue={hotelData.city}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Type"
            type="text"
            name="type"
            fullWidth
            defaultValue={hotelData.type}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="ratiing"
            label="Building Area"
            type="text"
            name="building_area"
            fullWidth
            defaultValue={hotelData.building_area}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Land Area"
            type="text"
            name="land_area"
            fullWidth
            defaultValue={hotelData.land_area}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Investment Main Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          {/* New PDF file input */}
          <Box mt={2}>
            <input
              type="file"
              accept="application/pdf"
              name="pdf"
              id="pdfFile"
              onChange={loadPDFFile}
              style={{ display: "none" }}
            />
            <label htmlFor="pdfFile">
              <Button variant="contained" color="primary" component="span">
                Upload PDF
              </Button>
            </label>
            {selectedPDF ? (
              <Typography variant="caption" display="block">
                {selectedPDF.name}
              </Typography>
            ) : hotelData && hotelData.pdf_file ? (
              <Typography variant="caption" display="block">
                <a
                  href={hotelData.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pdf}
                </a>
              </Typography>
            ) : (
              <Typography variant="caption" display="block">
                No PDF file uploaded
              </Typography>
            )}
          </Box>

          <TextField
            margin="dense"
            id="latitude"
            label="Units Count"
            type="text"
            name="units_count"
            fullWidth
            defaultValue={hotelData.units_count}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="booknowlink"
            label="Floors"
            type="text"
            fullWidth
            name="floors"
            defaultValue={hotelData.floors}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="booknowlink"
            label="Latitude"
            type="text"
            fullWidth
            name="lat"
            defaultValue={hotelData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="booknowlink"
            label="Longitude"
            type="text"
            fullWidth
            name="lng"
            defaultValue={hotelData.lng}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={8000}
      />
      <Snackbar
        open={staticErrorOpen}
        onClose={handleStaticErrorClose}
        TransitionComponent={TransitionUp}
        message={<span id="static-error-id">{staticError}</span>}
        ContentProps={{
          "aria-describedby": "static-error-id",
        }}
        anchorOrigin={{
          vertical: "top", // Position at the top
          horizontal: "center", // Center horizontally
        }}
      />
    </div>
  );
}

export default EditInvestment;
