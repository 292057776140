import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Wrapper } from '../../components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import TableCell from '@material-ui/core/TableCell';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import EditSettings from "./EditSettings"; 
import api from "../../api";
import LinearProgress from "@material-ui/core/LinearProgress";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  cover: {
    width: 40,
    height: 25
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(3),
  },
  flexSpacer: {
    flex: 1,
  },
});

function Settings(props) {
  const { classes } = props;

  const [open, setOpen] = useState(false);
  const [openadmin, setadminOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});

  const handleNewAdminClick = () => {
    setadminOpen(true);
  };





  // const handleDeleteClick = (museumId) => {
  //   setDeleteConfirmationOpen(true);
  // };

  const handleEditClick = async () => {
    setOpen(true); // Open the dialog when edit icon is clicked
  };






  useEffect(() => {
    setLoading(true);
    const fetchSettings = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/customer/settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setSettings(response.data.data);
        } else {
          setSettings({});
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
        setSettings({});
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [ openadmin, open]);

  return (
    <div>
      <Wrapper>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flexSpacer}
            >
              Settings
            </Typography>
            <Button
              disabled
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleNewAdminClick}
            >
              New
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={1}>
          {loading && <LinearProgress />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Android Version</CustomTableCell>
                <CustomTableCell>IOS Version</CustomTableCell>
                <CustomTableCell>Google Map Key</CustomTableCell>
                <CustomTableCell>Googleplay Url</CustomTableCell>
                <CustomTableCell>Apple store Url</CustomTableCell>
                <CustomTableCell>Action</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row} key={1}>
                <CustomTableCell>{settings.android_version}</CustomTableCell>
                <CustomTableCell>{settings.ios_version}</CustomTableCell>
                <CustomTableCell>{settings.google_map_key}</CustomTableCell>
                <CustomTableCell>{settings.google_play_url}</CustomTableCell>
                <CustomTableCell>{settings.apple_store_url}</CustomTableCell>
                <CustomTableCell>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon color="primary" />
                  </IconButton>

                  {/* <IconButton  className={classes.iconButton} onClick={() => handleDeleteClick(museum.id)}>
                      <DeleteIcon color="secondary" />
                    </IconButton> */}
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <EditSettings
          open={open}
          setOpen={setOpen}
          handleClose={() => setOpen(false)}
        />
      </Wrapper>
    </div>
  );
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
