import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "../../components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import CardMedia from "@material-ui/core/CardMedia";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CollectionsIcon from '@material-ui/icons/Collections';
import ShopSlider from "./ShopSlider";
import EditShop from "./EditShop";
import NewShop from "./NewShop";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  cover: {
    width: 40,
    height: 25,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
  iconButton: {
    padding: theme.spacing(0.5), // adjust padding as needed
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "200px",
    color: "white",
    "&:before": {
      borderBottom: "1px solid white",
    },
  },
});

function Shop(props) {
  const { classes } = props;
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openadmin, setadminOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hotelData, sethotelData] = useState(1);
  const [hotels, setHotels] = useState([]);
  const [deleteHotelId, setDeleteHotelId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [hotelslider, setHotelSlider] = useState(false);

  const handleNewAdminClick = () => {
    setadminOpen(true); // Open the dialog when edit icon is clicked
  };

  const optionCallback = () =>{
    setOpen(false)
  }


  const handleSliderClick = async (hotelId) => {
    setDeleteHotelId(hotelId);
   try {
     const token = sessionStorage.getItem("id_token");
     const response = await api.get(`/admin/malls/${hotelId}`, {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     });
 
     if (response.data.success) {
       // Extract options data from the API response
       const optionsData = response.data.data.sliders;
       // Pass the options data to the Options component through prop
       sethotelData(optionsData);
       setHotelSlider(true); // Open the Options component
     } else {
       // Handle the error response if needed
       console.error("Error fetching hotel options:", response.data.error);
     }
   } catch (error) {
     console.error("Error fetching hotel options:", error);
   }
   setDeleteHotelId(hotelId);
   setHotelSlider(true);
 };

  const handleDeleteClick = async (hotelId) => {
    setDeleteHotelId(hotelId);
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.delete(`/admin/malls/${deleteHotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Handle success response, maybe show a success message or update UI accordingly
        console.log("Malls deleted successfully");
      } else {
        // Handle error response if needed
        console.error("Error deleting Malls:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting Malls:", error);
    }
    handleDeleteConfirmationClose();
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleEditClick = async (hotelId) => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.get(`/admin/malls/${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Here you can handle the response, maybe set it to some state
        sethotelData(response.data.data);
        console.log(response.data.data);
      } else {
        // Handle the error response if needed
        console.error("Error fetching Mall data:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching Mall data:", error);
    }
    setOpen(true); // Open the dialog when edit icon is clicked
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const fetchCategories = async () => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.get("/admin/categories?type=mall", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find((category) => category.id === categoryId);
    console.log("Category hamid", category);
    return category ? category.name.en : "Unknown";
  };

  const filteredHotels = hotels.filter(
    (hotel) =>
      hotel.name &&
      hotel.name.en &&
      hotel.name.en.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);
    const fetchHotels = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/malls", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setHotels(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching malls:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotels();
    fetchCategories();
  }, [deleteConfirmationOpen, openadmin, open]);

  return (
    <div>
      <Wrapper>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flexSpacer}
            >
              Shop Information
            </Typography>
            <Input
              placeholder="Shop Name..."
              className={classes.searchInput}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => handleNewAdminClick()}
            >
              New
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={1}>
          {loading && <LinearProgress />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>S.No</CustomTableCell>
                <CustomTableCell>Shop Name</CustomTableCell>
                <CustomTableCell>Category</CustomTableCell>
                <CustomTableCell>Image</CustomTableCell>
                <CustomTableCell>Action</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHotels
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((hotel, index) => (
                  <TableRow className={classes.row} key={hotel.id}>
                    <CustomTableCell>
                      {page * rowsPerPage + index + 1}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {hotel.name.en}
                    </CustomTableCell>
                    <CustomTableCell>
                      {getCategoryName(hotel.category_id)}
                    </CustomTableCell>
                    <CustomTableCell>
                      <CardMedia
                        className={classes.cover}
                        image={hotel.image}
                        title="Hotel Image"
                      />
                    </CustomTableCell>
                    <CustomTableCell>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleEditClick(hotel.id)}
                      >
                        <EditIcon color="primary" />{" "}
                        {/* Pass the function to open the dialog */}
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleSliderClick(hotel.id)}
                      >
                        <CollectionsIcon color="secondary" />{" "}
                        {/* Pass the function to open the dialog */}
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleDeleteClick(hotel.id)}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredHotels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Shop?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirmationClose} color="primary">
              No
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <EditShop
          open={open}
          setOpen={setOpen}
          handleClose={() => setOpen(false)}
          hotelData={hotelData}
        />
        <ShopSlider
          hotelslider={hotelslider}
          setHotelSlider={setHotelSlider}
          handleClose={() => setHotelSlider(false)}
          HotelID={deleteHotelId}
          hotelData={hotelData}
          hotelCallBack={optionCallback}
        />
        <NewShop
          openadmin={openadmin}
          setadminOpen={setadminOpen}
          handleClose={() => setadminOpen(false)}
        />
      </Wrapper>
    </div>
  );
}

Shop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Shop);
