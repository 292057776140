import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Box, Paper, Typography } from "@material-ui/core";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function AddHotelSlider({ openadmin, handleClose, hotelId ,updateComponent}) {

  
  const [state, setstate] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    type: "mall",
  });

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };


  const handleAddOption = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = {
        type:"mall",
        id: hotelId && hotelId,
      }

      const formDataToSend = new FormData();

      formDataToSend.append("type", data.type);
      formDataToSend.append("id", data.id);
      

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post("/admin/sliders", formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        console.log("Image added:", response.data.data);
        setFormData({
           
        });
        setSelectedFile(null);
        handleClose(); // Close dialog after successful addition
        setError("Image Added to shop");
        setSnackbarOpen(true);
        updateComponent();
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding Image to the shop:", error);
      setError("Error adding Image shop");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Gallery to Shop </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can Add images to the shop.
          </DialogContentText>
       
          
          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                value={formData.image}
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddOption} color="primary">
            Add Image
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default AddHotelSlider;
