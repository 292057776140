import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Paper, Typography } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditShop({ open, hotelData, handleClose }) {
  const [state, setstate] = useState("");
  const image = hotelData.image;
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    men: "",
    mar: "",
    mfr: "",
    mur: "",
    mtr: "",
    msw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    lat: "",
    lng: "",
    category_id: "",
    phone_one: "",
    phone_two: "",
    phone_three: "",
    address: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

    var loadFile = (event) => {
      if (event.target.files) {
        const file = event.target.files[0];
        setstate(URL.createObjectURL(event.target.files[0]));
        const fileURL = URL.createObjectURL(event.target.files[0]);
        setSelectedFile(file);
        console.log(fileURL);
      }
    };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        mall_name: {
          en: formData.men,
          ar: formData.mar,
          fr: formData.mfr,
          ur: formData.mur,
          tr: formData.mtr,
          sw: formData.msw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        category_id: formData.category_id,
        lat: formData.lat,
        lng: formData.lng,
        phone_one: formData.phone_one,
        phone_two: formData.phone_two,
        phone_three: formData.phone_three,
        address:formData.address,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });
      Object.keys(data.mall_name).forEach((key) => {
        formDataToSend.append(`mall_name[${key}]`, data.mall_name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("category_id", data.category_id);
      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      formDataToSend.append("phone_one", data.phone_one);
      formDataToSend.append("phone_two", data.phone_two);
      formDataToSend.append("phone_three", data.phone_three);
      formDataToSend.append("address", data.address);

      

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/malls/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      ); 

      if (response.data.success) {
        console.log("Mall updated:", response.data.data);
        handleClose();
        setError("Mall updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Mall");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Mall:", error);
      setError("Error updating Mall");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // This effect runs when hotelData changes
    const fetchCategories = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/categories?type=mall", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (hotelData) {
      // Set formData based on hotelData when it's available
      setFormData({
        en: hotelData && hotelData.name && hotelData.name.en,
        ar: hotelData && hotelData.name && hotelData.name.ar,
        fr: hotelData && hotelData.name && hotelData.name.fr,
        ur: hotelData && hotelData.name && hotelData.name.ur,
        tr: hotelData && hotelData.name && hotelData.name.tr,
        sw: hotelData && hotelData.name && hotelData.name.sw,
        men: hotelData && hotelData.mall_name && hotelData.mall_name.en,
        mar: hotelData && hotelData.mall_name && hotelData.mall_name.ar,
        mfr: hotelData && hotelData.mall_name && hotelData.mall_name.fr,
        mur: hotelData && hotelData.mall_name && hotelData.mall_name.ur,
        mtr: hotelData && hotelData.mall_name && hotelData.mall_name.tr,
        msw: hotelData && hotelData.mall_name && hotelData.mall_name.sw,
        den: hotelData && hotelData.name && hotelData.description.en,
        dar: hotelData && hotelData.name && hotelData.description.ar,
        dfr: hotelData && hotelData.name && hotelData.description.fr,
        dur: hotelData && hotelData.name && hotelData.description.ur,
        dtr: hotelData && hotelData.name && hotelData.description.tr,
        dsw: hotelData && hotelData.name && hotelData.description.sw,
        lat: hotelData.lat,
        lng: hotelData.lng,
        category_id: hotelData.category_id,
        phone_one: hotelData.phone_one,
        phone_two: hotelData.phone_two,
        phone_three: hotelData.phone_three,
        address:hotelData.address
      });
      fetchCategories();
    }
  }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit Shop Detail of {hotelData && hotelData.name && hotelData.name.en}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Shop details or delete and add them again.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="shop-name"
            label="Shop Name in English"
            type="text"
            fullWidth
            name="en"
            defaultValue={hotelData && hotelData.name && hotelData.name.en}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Shop Name in Arabic"
            type="text"
            fullWidth
            name="ar"
            defaultValue={hotelData && hotelData.name && hotelData.name.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Shop Name in French"
            type="text"
            fullWidth
            name="fr"
            defaultValue={hotelData && hotelData.name && hotelData.name.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Shop Name in Urdu"
            type="text"
            fullWidth
            name="ur"
            defaultValue={hotelData && hotelData.name && hotelData.name.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Shop Name in Turkish"
            type="text"
            fullWidth
            name="tr"
            defaultValue={hotelData && hotelData.name && hotelData.name.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Shop Name in Swahili"
            type="text"
            fullWidth
            name="sw"
            defaultValue={hotelData && hotelData.name && hotelData.name.sw}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in English"
            type="text"
            fullWidth
            name="men"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.en
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in Arabic"
            type="text"
            fullWidth
            name="mar"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.ar
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in French"
            type="text"
            fullWidth
            name="mfr"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.fr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in Urdu"
            type="text"
            fullWidth
            name="mur"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.ur
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in Turkish"
            type="text"
            fullWidth
            name="mtr"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.tr
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Mall Name in Swahili"
            type="text"
            fullWidth
            name="msw"
            defaultValue={
              hotelData && hotelData.mall_name && hotelData.mall_name.sw
            }
            onChange={handleChange}
          />

          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Select Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="category_id"
              onChange={handleChange}
              error={formData.category_id === ""}
              value={formData.category_id}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name.en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.en
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ar
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.fr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ur
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.tr
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Shop Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.sw
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber1"
            label="Phone Number 1"
            type="number"
            fullWidth
            name="phone_one"
            defaultValue={hotelData && hotelData.phone_one}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber2"
            label="Phone Number 2"
            type="number"
            fullWidth
            name="phone_two"
            defaultValue={hotelData && hotelData.phone_two}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber3"
            label="Phone Number 3"
            type="number"
            fullWidth
            name="phone_three"
            defaultValue={hotelData && hotelData.phone_three}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            name="address"
            defaultValue={hotelData && hotelData.address}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="latitude"
            label="latitude"
            type="number"
            name="lat"
            fullWidth
            defaultValue={hotelData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="longitude"
            label="longitude"
            type="number"
            name="lng"
            fullWidth
            defaultValue={hotelData.lng}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                value={formData.image}
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Shop Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditShop;
