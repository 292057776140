import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "../../components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  cover: {
    width: 40,
    height: 25,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
  iconButton: {
    padding: theme.spacing(0.5), // adjust padding as needed
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "200px",
    color: "white",
    "&:before": {
      borderBottom: "1px solid white",
    },
  },
});

function Contacts(props) {
  const { classes } = props;
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const truncateTitle = (title) => {
    return title.length > 20 ? title.substring(0, 20) + "..." : title;
  };
  // const truncateDescription = (description) => {
  //   return description.length > 20
  //     ? description.substring(0, 20) + "..."
  //     : description;
  // };

  const filteredHotels = hotels.filter(
    (hotel) =>
      hotel.name &&
      hotel.name &&
      hotel.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);
    const fetchHotels = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/contacts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setHotels(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotels();
  }, []);

  return (
    <div>
      <Wrapper>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flexSpacer}
            >
              Contact Form Details
            </Typography>
            <Input
              placeholder="Contact Name..."
              className={classes.searchInput}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={1}>
          {loading && <LinearProgress />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>S.No</CustomTableCell>
                <CustomTableCell>Name</CustomTableCell>
                <CustomTableCell>Subject</CustomTableCell>
                <CustomTableCell>Email</CustomTableCell>
                <CustomTableCell>Phone</CustomTableCell>
                <CustomTableCell>Message</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHotels
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((hotel, index) => (
                  <TableRow className={classes.row} key={hotel.id}>
                    <CustomTableCell>
                      {page * rowsPerPage + index + 1}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {hotel.name}
                    </CustomTableCell>
                    <CustomTableCell>
                      {truncateTitle(hotel.title)}
                    </CustomTableCell>
                    <CustomTableCell>{hotel.email}</CustomTableCell>
                    <CustomTableCell>{hotel.phone}</CustomTableCell>
                    <CustomTableCell>{hotel.message}</CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredHotels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Wrapper>
    </div>
  );
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contacts);
