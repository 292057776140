import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Wrapper } from '../../components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import CollectionsIcon from '@material-ui/icons/Collections';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import NewMuseum from './NewMuseum';
import EditMuseum from './EditMuseum'; 
import MuseumSlider from "./MuseumSlider";
import api from "../../api";
import LinearProgress from "@material-ui/core/LinearProgress";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  cover: {
    width: 40,
    height: 25
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(3),
  },
  flexSpacer: {
    flex: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '200px',
    color: 'white',
    '&:before': {
      borderBottom: '1px solid white'
    }
  }
});

function Museum(props) {
  const { classes } = props;
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openadmin, setadminOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [museums, setMuseums] = useState([]);  // Initialize as an empty array
  const [loading, setLoading] = useState(false);
  const [hotelData, sethotelData] = useState(1);
  const [deleteHotelId, setDeleteHotelId] = useState(null);
  const [hotelslider, setHotelSlider] = useState(false);

  const handleNewAdminClick = () => {
    setadminOpen(true);
  };

  const optionCallback = () =>{
    setOpen(false)
  }

  const handleSliderClick = async (hotelId) => {
    setDeleteHotelId(hotelId);
   try {
     const token = sessionStorage.getItem("id_token");
     const response = await api.get(`/admin/museums/${hotelId}`, {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     });
 
     if (response.data.success) {
       // Extract options data from the API response
       const optionsData = response.data.data.sliders;
       // Pass the options data to the Options component through prop
       sethotelData(optionsData);
       setHotelSlider(true); // Open the Options component
     } else {
       // Handle the error response if needed
       console.error("Error fetching hotel options:", response.data.error);
     }
   } catch (error) {
     console.error("Error fetching hotel options:", error);
   }
   setDeleteHotelId(hotelId);
   setHotelSlider(true);
 };

  // const handleDeleteClick = (museumId) => {
  //   setDeleteConfirmationOpen(true);
  // };
  

  const handleDeleteConfirm = () => {
    // Perform delete operation here
    handleDeleteConfirmationClose();
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleEditClick = async (hotelId) => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.get(`/admin/museums/${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Here you can handle the response, maybe set it to some state
        sethotelData(response.data.data);
        console.log(response.data.data);
      } else {
        // Handle the error response if needed
        console.error("Error fetching hotel data:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching hotel data:", error);
    }
    setOpen(true); // Open the dialog when edit icon is clicked
  };

   const truncateDescription = (description) => {
     return description.length > 30
       ? description.substring(0, 30) + "..."
       : description;
   };


  const filteredMuseums = museums.filter(museum => 
    museum.name.en.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = event => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const fetchMuseums = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/museums", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setMuseums([response.data.data]); // Adjusted to handle a single museum
        } else {
          setMuseums([]);
        }
      } catch (error) {
        console.error("Error fetching museums:", error);
        setMuseums([]);
      } finally {
        setLoading(false);
      }
    };

    fetchMuseums();
  }, [deleteConfirmationOpen, openadmin, open]);

  return (
    <div>
      <Wrapper>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flexSpacer}
            >
              Museum Information
            </Typography>
            <Input
              placeholder="Museum Name..."
              className={classes.searchInput}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <Button
              disabled
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleNewAdminClick}
            >
              New
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={1}>
          {loading && <LinearProgress />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>S.No</CustomTableCell>
                <CustomTableCell>Museum Name</CustomTableCell>
                <CustomTableCell>Image</CustomTableCell>
                <CustomTableCell>Description</CustomTableCell>
                <CustomTableCell>Action</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMuseums
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((museum, index) => (
                  <TableRow className={classes.row} key={museum.id}>
                    <CustomTableCell>{museum.id}</CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {museum.name.en}
                    </CustomTableCell>
                    <CustomTableCell>
                      <CardMedia
                        className={classes.cover}
                        image={museum.image}
                        title="Museum Image"
                      />
                    </CustomTableCell>
                    <CustomTableCell>
                      {truncateDescription(museum.description.en)}
                    </CustomTableCell>
                    <CustomTableCell>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleEditClick(museum.id)}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleSliderClick(museum.id)}
                      >
                        <CollectionsIcon color="secondary" />{" "}
                        {/* Pass the function to open the dialog */}
                      </IconButton>
                      {/* <IconButton  className={classes.iconButton} onClick={() => handleDeleteClick(museum.id)}>
                      <DeleteIcon color="secondary" />
                    </IconButton> */}
                    </CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredMuseums.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Museum?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirmationClose} color="primary">
              No
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <MuseumSlider
          hotelslider={hotelslider}
          setHotelSlider={setHotelSlider}
          handleClose={() => setHotelSlider(false)}
          HotelID={deleteHotelId}
          hotelData={hotelData}
          hotelCallBack={optionCallback}
        />
        <EditMuseum
          open={open}
          setOpen={setOpen}
          handleClose={() => setOpen(false)}
          hotelData={hotelData}
        />
        <NewMuseum
          openadmin={openadmin}
          setadminOpen={setadminOpen}
          handleClose={() => setadminOpen(false)}
        />
      </Wrapper>
    </div>
  );
}

Museum.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Museum);
