import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditOptions({ open, handleClose, hotelData1,hotelId,currentOptionId, updateComponent }) {
  // for image preview
  const [state, setstate] = useState("");
  // const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");

  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    hotel_id: "",
    total_count: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL,imagePreview);
    }
  };

  const handleUpdateHotel = async () => {
    if (hotelData1 && hotelId && currentOptionId) {
   
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        hotel_id: hotelId,
        total_count: formData.total_count,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });


      formDataToSend.append("hotel_id", data.hotel_id);
      formDataToSend.append("total_count", data.total_count);
      


      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }


      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/options/${currentOptionId}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Option updated:", response.data.data);
        handleClose();
        setError("Option updated successfully");
        setSnackbarOpen(true);
        updateComponent();
      } else {
        setError("Error updating Option");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Option:", error);
      setError("Error updating Option");
    } finally {
      setLoading(false);
    }
  } else {
    setError("Missing required parameters");
    setSnackbarOpen(true);
  }
  };



  useEffect(() => {
    if (Array.isArray(hotelData1) && currentOptionId) {
      const currentOption = hotelData1.find((option) => option.id === currentOptionId);
      if (currentOption) {
        setFormData({
          en: currentOption.name?.en || "",
          ar: currentOption.name?.ar || "",
          fr: currentOption.name?.fr || "",
          ur: currentOption.name?.ur || "",
          tr: currentOption.name?.tr || "",
          sw: currentOption.name?.sw || "",
          hotel_id: currentOption.hotel_id || "",
          total_count: currentOption.total_count || "",
        });
        setImagePreview(currentOption.image);
      }
    }
  }, [hotelData1, currentOptionId]);

  let image = "";
  if (Array.isArray(hotelData1) && currentOptionId) {
    const currentOption = hotelData1.find((option) => option.id === currentOptionId);
    if (currentOption) {
      image = currentOption.image;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Editing Hotel Option of{" "}
          {formData.en }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Option details or delete and add them again.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Hotel Name (English)"
            type="text"
            fullWidth
            name="en"
            defaultValue={formData.en}
            // defaultValue={hotelData1 && hotelData1.hotel_id}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            defaultValue={formData.ar}
            // defaultValue={hotelData1 && hotelData1.name && hotelData1.name.ar}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (French)"
            type="text"
            fullWidth
            name="fr"
            defaultValue={formData.fr}
            // defaultValue={hotelData1 && hotelData1.name && hotelData1.name.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            defaultValue={formData.ur}
            // defaultValue={hotelData1 && hotelData1.name && hotelData1.name.ur}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Turkish)"
            type="text"
            fullWidth
            name="tr"
            defaultValue={formData.tr}
            // defaultValue={hotelData1 && hotelData1.name && hotelData1.name.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Hotel Name (Swahili)"
            type="text"
            fullWidth
            name="sw"
            defaultValue={formData.sw}
            // defaultValue={hotelData1 && hotelData1.name && hotelData1.name.sw}
            onChange={handleChange}
          />

         
          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Hotel Main Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>


          <TextField
            margin="dense"
            id="ratiing"
            label="Total Count"
            type="text"
            name="total_count"
            fullWidth
            // value={hotelData1 && hotelData1[0] && hotelData1[0].total_count}
            // value={formData.total_count}
            defaultValue={formData.total_count}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditOptions;
