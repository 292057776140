import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { withStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
  addicon: {
    fontSize: 16, // adjust the size as needed
  },
  removeicon: {
    fontSize: 16, // adjust the size as needed
  },
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function NewInvestment({ openadmin, handleClose, classes, ref }) {
  // for image preview
  const [state, setstate] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [staticErrorOpen, setStaticErrorOpen] = useState(false);
  const [error, setError] = useState(null);
  const [staticError, setStaticError] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);

  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    status: "",
    city: "",
    type: "",
    building_area: "",
    land_area: "",
    units_count: "",
    floors: "",
    lat: "",
    lng: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const loadPDFFile = (event) => {
    if (event.target.files) {
      const pdfFile = event.target.files[0];
      setSelectedPDF(pdfFile); // Store the selected PDF file
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
    const handleStaticErrorClose = () => {
      setStaticErrorOpen(false);
    };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: formData.sw,
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: formData.dsw,
        },
        status: formData.status,
        city: formData.city,
        type: formData.type,
        building_area: formData.building_area,
        land_area: formData.land_area,
        units_count: formData.units_count,
        floors: formData.floors,
        lat: formData.lat,
        lng: formData.lng,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("status", data.status);
      formDataToSend.append("city", data.city);
      formDataToSend.append("type", data.type);
      formDataToSend.append("building_area", data.building_area);
      formDataToSend.append("land_area", data.land_area);
      formDataToSend.append("units_count", data.units_count);
      formDataToSend.append("floors", data.floors);
      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      if (selectedFile && selectedPDF) {
        formDataToSend.append("image", selectedFile);
        formDataToSend.append("pdf_file", selectedPDF);

        for (let [key, value] of formDataToSend.entries()) {
          console.log(`${key}: ${value}`);
        }

        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/projects", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200 || response.status === 201) {
          console.log("New investement added:", response.data.data);

          setFormData({
            en: "",
            ar: "",
            fr: "",
            ur: "",
            tr: "",
            sw: "",
            den: "",
            dar: "",
            dfr: "",
            dur: "",
            dtr: "",
            dsw: "",
            status: "",
            city: "",
            type: "",
            building_area: "",
            land_area: "",
            units_count: "",
            floors: "",
            lat: "",
            lng: "",
          });
          setstate("");
          setSelectedFile(null);
          handleClose(); // Close dialog after successful addition
          setError("New Investment Opportunity added");
          setSnackbarOpen(true);
        }
      } else {
        setError("Please select both a file image and a PDF");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      // console.error("Error adding Investment Opportunity:", error);
      setError("Error adding Investment Opportunity", error);

       if (error.response && error.response.data) {
         // Extract and format error messages from the API response
         const { message, data } = error.response.data;
         const formattedErrors = [message];

         if (data) {
           Object.keys(data).forEach((key) => {
             formattedErrors.push(...data[key]);
           });
         }

         setStaticError(formattedErrors.join(" | "));
       }
       setStaticErrorOpen(true);

    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          New Investment Opportunity Detail
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new Investment Opportunity just enter the following details
            all are required fields
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Investment Name (English)"
            type="text"
            fullWidth
            name="en"
            error={formData.en === ""}
            value={formData.en}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            error={formData.ar === ""}
            value={formData.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (French)"
            type="text"
            fullWidth
            name="fr"
            error={formData.fr === ""}
            value={formData.fr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            error={formData.ur === ""}
            value={formData.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Turkish)"
            type="text"
            fullWidth
            error={formData.tr === ""}
            name="tr"
            value={formData.tr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Investment Name (Swahili)"
            type="text"
            fullWidth
            name="sw"
            error={formData.sw === ""}
            value={formData.sw}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            error={formData.den === ""}
            value={formData.den}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            error={formData.dar === ""}
            value={formData.dar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            error={formData.dfr === ""}
            value={formData.dfr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            error={formData.dur === ""}
            value={formData.dur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            error={formData.dtr === ""}
            value={formData.dtr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Investment Description (Swahili)"
            type="text"
            fullWidth
            name="dsw"
            multiline
            rows={3}
            error={formData.dsw === ""}
            value={formData.dsw}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="price"
            label="Status"
            type="text"
            fullWidth
            name="status"
            error={formData.status === ""}
            value={formData.status}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="ratiing"
            label="City"
            type="text"
            name="city"
            fullWidth
            error={formData.city === ""}
            value={formData.city}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Type"
            type="text"
            name="type"
            fullWidth
            error={formData.type === ""}
            value={formData.type}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Building Area"
            type="number"
            name="building_area"
            fullWidth
            error={formData.building_area === ""}
            value={formData.building_area}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Land Area"
            type="number"
            name="land_area"
            fullWidth
            error={formData.land_area === ""}
            value={formData.land_area}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Units Count"
            type="number"
            name="units_count"
            fullWidth
            error={formData.units_count === ""}
            value={formData.units_count}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="ratiing"
            label="Floors"
            type="number"
            name="floors"
            fullWidth
            error={formData.floors === ""}
            value={formData.floors}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                multiple
                value={formData.image}
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Investment Main Image *
              </Typography>

              <img
                src={state ? state : image}
                className={classes.image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          {/* New PDF file input */}
          <Box mt={2}>
            <input
              type="file"
              accept="application/pdf"
              name="pdf"
              id="pdfFile"
              multiple
              onChange={loadPDFFile}
              style={{ display: "none" }}
            />
            <label htmlFor="pdfFile">
              <Button variant="contained" color="primary" component="span">
                Upload PDF *
              </Button>
            </label>
            {selectedPDF ? (
              <Typography variant="caption" display="block">
                {selectedPDF.name}
              </Typography>
            ) : (
              <Typography variant="caption" display="block">
                No PDF file selected
              </Typography>
            )}
          </Box>

          <TextField
            margin="dense"
            id="latitude"
            label="latitude"
            type="number"
            name="lat"
            fullWidth
            error={formData.lat === ""}
            value={formData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="longitude"
            label="longitude"
            type="number"
            name="lng"
            fullWidth
            error={formData.lng === ""}
            value={formData.lng}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress style={{ height: "15px" }} />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={8000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />

      <Snackbar
        open={staticErrorOpen}
        onClose={handleStaticErrorClose}
        TransitionComponent={TransitionUp}
        message={<span id="static-error-id">{staticError}</span>}
        ContentProps={{
          "aria-describedby": "static-error-id",
        }}
        anchorOrigin={{
          vertical: "top", // Position at the top
          horizontal: "center", // Center horizontally
        }}
      />

    </div>
  );
}

export default withStyles(styles)(NewInvestment);
